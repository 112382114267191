import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO,
  GET_MENU_LIST,
  GET_MENU_LIST_SUCCESS,
  SET_USER_TOKEN,
  SET_REFRESHING_TOKEN,
  GET_USER_INFO_ERROR,
  SET_USER_SCOPE,
} from "../actions";

const INIT_STATE = {
  user: localStorage.getItem("user_id"),
  loading: false,
  isRefreshingToken: false,
  error: "",
  userInfo: "",
  menuList: [],
  scope: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        error: "",
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.payload.message,
      };
    case SET_USER_TOKEN:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        isRefreshingToken: false,
        error: "",
      };
    case SET_REFRESHING_TOKEN:
      return {
        ...state,
        isRefreshingToken: true,
      };
    case GET_USER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        userInfo: "",
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: "" };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.id, error: "" };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return { ...state, loading: true, error: "" };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        error: "",
        token: null,
        refreshToken: null,
      };
    case GET_USER_INFO:
      return { ...state, loading: true, error: "" };
    case GET_USER_INFO_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload, error: "" };
    case GET_MENU_LIST:
      return { ...state, loading: true, error: "" };
    case GET_MENU_LIST_SUCCESS:
      return { ...state, loading: false, menuList: action.payload, error: "" };
    case SET_USER_SCOPE:
      return {
        ...state,
        scope: action.payload.scope,
        loading: false,
        error: "",
      };
    default:
      return { ...state };
  }
};
