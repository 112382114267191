import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import {
  LOGIN_USER,
  LOGOUT_USER,
  REDIRECT_LOGIN,
  GET_USER_INFO,
  GET_MENU_LIST,
  REFRESH_TOKEN,
  HANDLE_INVALID_TOKEN,
  CHECK_AUTHORIZATION,
} from "../actions";
import {
  loginUserSuccess,
  loginUserError,
  logoutUserSuccess,
  getUserInfoSuccess,
  getUserInfo as getUserInfoAction,
  getMenuList,
  getMenuListSuccess,
  setUserToken,
  setRefreshingToken,
  getUserInfoError,
  setUserScope,
} from "./actions";
import axios from "axios";
import APIServices from "../../service/api/api";
import * as jose from "jose";
import menuItems from "../../constants/menu";
import cryptoRandomString from "../../libs/crypto-random-string";

const loginUser = (code) => {
  const codeChallenge = localStorage.getItem("code_challenge");
  return new Promise((resolve, reject) => {
    APIServices.login(code, codeChallenge, (err, res) => {
      if (err) return reject(err);
      const jwt = res?.access_token;
      const refreshToken = res?.refresh_token;
      const data = jose.decodeJwt(jwt);
      const { sub, scope } = data;
      resolve({ jwt, user_id: sub, refreshToken, user_scope: scope });
    });
  });
};

async function getTokenByRefreshToken(refreshToken) {
  const { refresh_token, access_token } =
    await APIServices.getTokenByRefreshToken(refreshToken);
  return { refreshToken: refresh_token, accessToken: access_token };
}

const handleRedirect = () => {
  const arrayUserPaths = [
    "/user/forgot-password",
    "/user/register",
    "/user/verify-otp",
    "/user/create-new-password",
  ];
  // let randomCodeChallenge = "E9Melhoa2OwvFrEMTJguCHaoeK1t8URWbuGJSstw-cM";
  let codeChallenge = cryptoRandomString({
    length: 43,
    type: "url-safe",
  });
  localStorage.setItem("code_challenge", codeChallenge);

  if (arrayUserPaths.includes(window.location.pathname)) {
    return;
  }

  const url = axios.getUri({
    baseURL: `${process.env.REACT_APP_LOGIN_API_URL}/auth`,
    params: {
      client_id: process.env.REACT_APP_OAUTH2_SHR_CLIENT_ID,
      response_type: "code",
      scope: [
        "openid",
        "email",
        "profile",
        "graphql.admin.shop.promotions",
        "graphql.admin.shop.promotions.readonly",
        "graphql.admin.shop.operators",
        "graphql.admin.shop.operators.readonly",
        "graphql.admin.shop.hubs",
        "graphql.admin.shop.hubs.readonly",
        "graphql.admin.shop.shops",
        "graphql.admin.shop.shops.readonly",
        "graphql.admin.shop.regions",
        "graphql.admin.shop.regions.readonly",
        "graphql.admin.shop.langs",
        "graphql.admin.shop.langs.readonly",
        "graphql.admin.shop.users",
        "graphql.admin.shop.users.readonly",
        "graphql.admin.shop.categories",
        "graphql.admin.shop.categories.readonly",
        "graphql.admin.shop.products",
        "graphql.admin.shop.products.readonly",
        "graphql.admin.shop.sellerProducts",
        "graphql.admin.shop.sellerProducts.readonly",
        "graphql.admin.shop.brands",
        "graphql.admin.shop.brands.readonly",
        "graphql.admin.ekyc.issuers",
        "graphql.admin.ekyc.issuers.readonly",
        "graphql.admin.ekyc.enroll",
        "graphql.admin.ekyc.enroll.readonly",
        "graphql.admin.logs.smartengine",
        "graphql.admin.logs.smartengine.readonly",
        "graphql.admin.logs.facematch",
        "graphql.admin.logs.facematch.readonly",
        "graphql.admin.logs.detectface",
        "graphql.admin.logs.detectface.readonly",
        "graphql.admin.logs.tencent",
        "graphql.admin.logs.tencent.readonly",
        "graphql.admin.travel.bookings",
        "graphql.admin.travel.bookings.readonly",
        "graphql.admin.travel.refunds",
        "graphql.admin.travel.refunds.readonly",
        "graphql.admin.access",
        "graphql.admin.access.readonly",
        "docissuance.admin.ekyc.issuers",
        "docissuance.admin.ekyc.issuers.readonly",
        "docissuance.admin.ekyc.kycissuers",
        "docissuance.admin.ekyc.kycissuers.readonly",
        "docissuance.admin.ekyc.user-status-histories",
        "docissuance.admin.ekyc.user-status-histories.readonly",
        "docissuance.admin.prefunds",
        "docissuance.admin.prefunds.readonly",
        "docissuance.admin.ekyc.docfields",
        "docissuance.admin.ekyc.docfields.readonly",
        "swap.admin.stats",
        "swap.admin.stats.readonly",
        "swap.admin.settings",
        "swap.admin.settings.readonly",
        "nft.admin.stats",
        "nft.admin.stats.readonly",
        "nft.admin.settings",
        "nft.admin.settings.readonly",
        "auth.admin.readonly",
        "auth.admin.roles.readonly",
        "auth.admin.roles",
        "auth.admin.users.readonly",
        "auth.admin.users",
        "notification.admin.settings",
        "notification.admin.settings.readonly",
        "recon.wallet",
        "recon.transaction",
        "recon.distribution_rule",
        "recon.wallet.readonly",
        "recon.transaction.readonly",
        "recon.distribution_rule.readonly",
        "recon.halt_rule",
        "recon.halt_rule.readonly",
        "recon.notification",
        "recon.notification.readonly",
        "graphql.admin.access",
        "graphql.admin.access.readonly",
        "common.admin.license",
        "common.admin.license.readonly",
        "common.admin.global-settings",
        "graphql.admin.promotions.home_banner",
        "graphql.admin.promotions.home_banner.readonly",
        "identity.admin.vct_revoke",
        "identity.admin.vct_revoke.readonly",
        "vql.admin.query_owner",
        "vql.admin.query_owner.readonly",
        "vql.admin.query_report",
        "vql.admin.query_report.readonly",
        "vql.admin.vql_client_profiles.readonly",
        "vql.admin.impersonate_login",
      ].join(" "),
      nonce: "foobar",
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
      code_challenge: codeChallenge,
      code_challenge_method: "plain",
      prompt: "select_account",
    },
  });
  window.location.href = url;
};

const getUser = (userId) => {
  return new Promise((resolve, reject) => {
    APIServices.getDetailUser(userId, (err, res) => {
      if (err) return reject(err);
      resolve(res);
    });
  });
};

const handleGetMenuList = (user_id) => {
  return new Promise((resolve, reject) => {
    APIServices.getDetailUser(user_id, (err, res) => {
      if (err) return reject(err);
      const moduleList = res?.data?.adminRole?.modules;

      let filterModuleList = [];
      menuItems.map((item) => {
        moduleList.forEach((module) => {
          if (module.moduleName.toLowerCase() === item.id.toLowerCase()) {
            filterModuleList.push({
              ...item,
              permission: module.permission,
            });
          }
        });
      });
      resolve(filterModuleList);
    });
  });
};

function* loginWithEmailPassword({ payload }) {
  const { code } = payload;

  try {
    const { jwt, user_id, refreshToken, user_scope } = yield call(
      loginUser,
      code
    );
    localStorage.setItem("auth-token", jwt);
    localStorage.setItem("user_id", user_id);
    localStorage.setItem("refresh-token", refreshToken);
    const jwkKey = JSON.parse(process.env.REACT_APP_JWT_JWK_PUBLIC_KEY || "{}");
    const publicKey = yield call(jose.importJWK, jwkKey, "ES256", false);
    try {
      yield call(jose.jwtVerify, jwt, publicKey);
    } catch (err) {
      yield call(handleRedirect);
      return;
    }
    yield put(loginUserSuccess(user_id, jwt, refreshToken));
    yield put(getUserInfoAction(user_id));
    yield put(getMenuList(user_id));
    yield put(setUserScope(user_scope));
  } catch (error) {
    yield put(loginUserError(error));
  }
}

function* redirectLogin() {
  // const token = localStorage.getItem("auth-token");
  // const user_id = localStorage.getItem("user_id");
  // const refreshToken = localStorage.getItem("refresh-token");
  // const arrayUserPaths = [
  //   "/user/forgot-password",
  //   "/user/register",
  //   "/user/verify-otp",
  //   "/user/create-new-password",
  // ];

  // if (token) {
  // const privateKey = yield call(jose.importJWK, jwks.keys[1], "ES256", true);
  // try {
  //   yield call(jose.jwtVerify, token, privateKey);
  // } catch (err) {
  //   // yield put(redirectLoginAction());
  //   yield call(handleRedirect);
  //   return;
  // }
  // yield put(setUserToken(token, refreshToken));
  // yield put(getUserInfoAction(user_id));
  // yield put(getMenuList(user_id));
  // return;
  // }
  // if (!arrayUserPaths.includes(window.location.pathname)) {
  //   yield put(getUserInfoAction(user_id));
  //   yield put(getMenuList(user_id));
  // }

  yield call(handleRedirect);
}

function* checkAuthorization() {
  const token = localStorage.getItem("auth-token");
  const user_id = localStorage.getItem("user_id");
  const refreshToken = localStorage.getItem("refresh-token");
  const arrayUserPaths = [
    "/user/forgot-password",
    "/user/register",
    "/user/verify-otp",
    "/user/create-new-password",
  ];

  if (token) {
    const jwkKey = JSON.parse(process.env.REACT_APP_JWT_JWK_PUBLIC_KEY || "{}");
    const publicKey = yield call(jose.importJWK, jwkKey, "ES256", false);
    let payload;
    try {
      const result = yield call(jose.jwtVerify, token, publicKey);
      payload = result.payload;
    } catch (err) {
      yield call(handleRedirect);
      return;
    }
    yield put(setUserToken(token, refreshToken));
    yield put(setUserScope(payload.scope));
    yield put(getUserInfoAction(user_id));
    yield put(getMenuList(user_id));
    return;
  }
  if (!arrayUserPaths.includes(window.location.pathname)) {
    yield put(getUserInfoAction(user_id));
    yield put(getMenuList(user_id));
  }
}

function* handleInvalidToken() {
  localStorage.removeItem("auth-token");
  localStorage.removeItem("user_id");
  localStorage.removeItem("refresh-token");
  yield put(logoutUserSuccess());
  yield call(handleRedirect);
}

function* getUserInfo({ payload }) {
  const { userId } = payload;
  try {
    const response = yield call(getUser, userId);
    yield put(
      getUserInfoSuccess(
        `${response?.data?.firstName} ${response?.data?.lastName}`
      )
    );
  } catch (err) {
    yield put(getUserInfoError(err.message));
  }
}

function* getUserMenuList({ payload }) {
  const { userId } = payload;
  const response = yield call(handleGetMenuList, userId);
  yield put(getMenuListSuccess(response));
}

function* logout() {
  yield put(logoutUserSuccess());
  localStorage.removeItem("user_id");
  localStorage.removeItem("auth-token");
  localStorage.removeItem("refresh-token");
  const endSessionBaseUrl = `${process.env.REACT_APP_LOGIN_API_URL}/session/end`;
  const endSessionUrl = axios.getUri({
    baseURL: endSessionBaseUrl,
    params: {
      client_id: process.env.REACT_APP_OAUTH2_SHR_CLIENT_ID,
      post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    },
  });
  window.location.href = endSessionUrl;
}

function* handleRefreshToken() {
  try {
    yield put(setRefreshingToken());
    const refreshToken = localStorage.getItem("refresh-token");
    const resp = yield call(getTokenByRefreshToken, refreshToken);
    const { accessToken, refreshToken: newRefreshToken } = resp;
    localStorage.setItem("auth-token", accessToken);
    localStorage.setItem("refresh-token", newRefreshToken);
    yield put(setUserToken(accessToken, newRefreshToken));
  } catch (err) {
    console.log(err);
    yield put(setUserToken("", ""));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_USER_INFO, getUserInfo),
    takeEvery(LOGIN_USER, loginWithEmailPassword),
    takeEvery(REDIRECT_LOGIN, redirectLogin),
    takeEvery(LOGOUT_USER, logout),
    takeLeading(REFRESH_TOKEN, handleRefreshToken),
    takeEvery(HANDLE_INVALID_TOKEN, handleInvalidToken),
    takeEvery(GET_MENU_LIST, getUserMenuList),
    takeEvery(CHECK_AUTHORIZATION, checkAuthorization),
  ]);
}
