/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const REDIRECT_LOGIN = "REDIRECT_LOGIN";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_MENU_LIST = "GET_MENU_LIST";
export const GET_MENU_LIST_SUCCESS = "GET_MENU_LIST_SUCCESS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_REFRESHING_TOKEN = "SET_REFRESHING_TOKEN";
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR";
export const HANDLE_INVALID_TOKEN = "HANDLE_INVALID_TOKEN";
export const CHECK_AUTHORIZATION = "CHECK_AUTHORIZATION";
export const SET_USER_SCOPE = "SET_USER_SCOPE";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* PAGENUMBER */
export const LOGS = "LOGS";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./pageNumbers/actions";
