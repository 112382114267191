import { axiosInstance } from "./axiosInstance";
import qs from "qs";

export default {
  async baseApi(sub_url, method, json_data, type = "COVID", cb) {
    let authToken = localStorage["auth-token"];

    try {
      let request = {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authToken ? "Bearer " + authToken : null,
        },
      };

      if (method == "POST" || method == "PUT") {
        request["body"] = JSON.stringify(json_data);
      }

      const base_url =
        type === "AUTH"
          ? process.env.REACT_APP_AUTH_API_URL
          : type === "SWAP"
          ? process.env.REACT_APP_SWAP_API_URL
          : process.env.REACT_APP_COVID_API_URL;
      let response = await fetch(base_url + sub_url, request);
      let responseJson = await response.json();
      if (response.status >= 200 && response.status < 300) {
        cb && cb(null, responseJson);
      } else {
        cb && cb(responseJson, null);
      }
    } catch (error) {
      cb && cb(error, null);
    }
  },

  async baseApi_v2(sub_url, method, json_data, type = "SWAP", params, cb) {
    try {
      let response = await axiosInstance(type).request({
        method: method,
        url: sub_url,
        params: params,
        data: json_data,
      });
      let responseData = await response.data;
      if (!cb) {
        return response;
      }
      return cb && cb(null, responseData);
    } catch (error) {
      if (!cb) {
        return {
          success: false,
          error: error.message,
        };
      }
      return cb && cb(error, null);
    }
  },

  async baseApi_Swap_ExportCSV(
    sub_url,
    method,
    json_data,
    type = "SWAP",
    params,
    cb
  ) {
    try {
      let response = await axiosInstance(type).request({
        method: method,
        url: sub_url,
        params: params,
        data: json_data,
        responseType: "blob",
        headers: {
          Accept: "",
        },
      });
      let responseData = await response.data;

      if (response.status >= 200 && response.status < 300) {
        cb && cb(null, responseData);
      } else {
        cb && cb(responseData, null);
      }
    } catch (error) {
      cb && cb(error, null);
    }
  },

  async baseApi_downloadVQL(
    sub_url,
    method,
    json_data,
    type = "SWAP",
    params,
    cb
  ) {
    try {
      let response = await axiosInstance(type).request({
        method: method,
        url: sub_url,
        params: params,
        data: json_data,
        responseType: "blob",
        headers: {
          Accept: "",
        },
      });
      let responseData = {
        data: response.data,
        headers: response.headers,
      };

      if (response.status >= 200 && response.status < 300) {
        cb && cb(null, responseData);
      } else {
        cb && cb(responseData, null);
      }
    } catch (error) {
      cb && cb(error, null);
    }
  },

  async backendApi(sub_url, method, json_data, cb) {
    let authToken = localStorage["auth-token"];

    try {
      let request = {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authToken ? "Bearer " + authToken : null,
        },
      };
      if (method == "POST" || method == "PUT") {
        request["body"] = JSON.stringify(json_data);
      }
      console.log("authToken:", authToken);
      const base_url = process.env.REACT_APP_BACKEND_API_URL;
      let response = await fetch(base_url + sub_url, request);
      let responseJson = await response.json();
      if (response.status >= 200 && response.status < 300) {
        cb && cb(null, responseJson);
      } else {
        cb && cb(responseJson, null);
      }
    } catch (error) {
      cb && cb(error, null);
    }
  },

  async init(cb) {
    var email = localStorage.email;
    var password = localStorage.password;
    if (password) {
      this.login(email, password, (err, user) => {
        cb(err, user);
      });
    } else {
      cb(null);
    }
  },
  async login(code, code_verifier, cb) {
    const data = {
      client_id: process.env.REACT_APP_OAUTH2_SHR_CLIENT_ID,
      code: code,
      grant_type: "authorization_code",
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
      code_verifier: code_verifier,
      // access_type: "offline",
    };

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      maxRedirects: 0,
      data: qs.stringify(data),
      url: "/token",
    };

    try {
      // let response = await axios(options);
      let response = await axiosInstance("LOGIN").request(options);
      let responseData = await response.data;

      if (!cb) {
        return response;
      }
      return cb && cb(null, responseData);
    } catch (error) {
      if (!cb) {
        return {
          success: false,
          error: error.message,
        };
      }
      return cb && cb(error, null);
    }
  },
  async getTokenByRefreshToken(refreshToken, cb) {
    const data = {
      client_id: process.env.REACT_APP_OAUTH2_SHR_CLIENT_ID,
      refresh_token: refreshToken,
      grant_type: "refresh_token",
    };

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      maxRedirects: 0,
      data: qs.stringify(data),
      url: "/token",
    };

    const response = await axiosInstance("LOGIN").request(options);
    const responseData = await response.data;
    return responseData;
  },
  checkFirstLogin(cb) {
    return this.baseApi_v2("/me", "GET", {}, "AUTH", {}, cb);
  },
  register(cb) {
    return this.baseApi_v2("/register", "POST", {}, "AUTH", {}, cb);
  },
  changePassword(cb) {
    return this.baseApi_v2("/change-password", "PUT", {}, "AUTH", {}, cb);
  },
  resetPassword(email, cb) {
    return this.baseApi_v2(
      "/reset-password",
      "POST",
      { email },
      "AUTH",
      {},
      cb
    );
  },
  validateResetPasswordToken(email, token, cb) {
    return this.baseApi_v2(
      "/reset-password/validate",
      "POST",
      { email, token },
      "AUTH",
      {},
      cb
    );
  },
  resetPasswordPut(email, token, password, cb) {
    return this.baseApi_v2(
      "/reset-password",
      "PUT",
      { email, token, newPassword: password, newPasswordRepeat: password },
      "AUTH",
      {},
      cb
    );
  },
  getIssuers(page, size, cb) {
    return this.baseApi(
      `/admin/issuers/?page=${page}&size=${size}`,
      "GET",
      {},
      "COVID",
      cb
    );
  },
  enRollIssuer(id, cb) {
    return this.baseApi(`/admin/issuers/${id}/enroll`, "PUT", {}, "COVID", cb);
  },
  revokeIssuer(id, cb) {
    return this.baseApi(`/admin/issuers/${id}/revoke`, "PUT", {}, "COVID", cb);
  },

  getKycIssuers(page, size, cb) {
    return this.baseApi(
      `/admin/kycissuers/?page=${page}&size=${size}&sort=-createdAt`,
      "GET",
      {},
      "COVID",
      cb
    );
  },
  enRollKycIssuer(id, cb) {
    return this.baseApi(
      `/admin/kycissuers/${id}/enroll`,
      "PUT",
      {},
      "COVID",
      cb
    );
  },
  revokeKycIssuer(id, cb) {
    return this.baseApi(
      `/admin/kycissuers/${id}/revoke`,
      "PUT",
      {},
      "COVID",
      cb
    );
  },
  getUserStatusHistories(page, size, fromDate, toDate, sort, address, cb) {
    return this.baseApi(
      `/admin/user-status-histories/?page=${page}&size=${size}&sort=${sort}`,
      "GET",
      {},
      "COVID",
      cb
    );
  },
  getPrefunds(page, size, fromDate, toDate, s, cb) {
    if (fromDate !== null && toDate !== null) {
      return this.baseApi(
        `/admin/prefunds?page=${page}&size=${size}&fromDate=${fromDate}&toDate=${toDate}&s=${s}`,
        "GET",
        {},
        "",
        cb
      );
    } else {
      return this.baseApi(
        `/admin/prefunds?page=${page}&size=${size}&s=${s}`,
        "GET",
        {},
        "",
        cb
      );
    }
  },
  getAllDocTypes(page, size, sort, cb) {
    return this.baseApi(
      `/admin/doctypes/?page=${page}&size=${size}&sort=${sort}}`,
      "GET",
      {},
      "COVID",
      cb
    );
  },
  getDocumentValues(page, size, sort, documentType, fieldName, cb) {
    return this.baseApi(
      `/admin/docvalues/?page=${page}&size=${size}&sort=${sort}&documentType=${documentType}&fieldName=${fieldName}`,
      "GET",
      {},
      "COVID",
      cb
    );
  },
  addDocumentValue(params, cb) {
    return this.baseApi(`/admin/docvalues`, "POST", params, "COVID", cb);
  },
  editDocumentValue(id, params, cb) {
    return this.baseApi(`/admin/docvalues/${id}`, "PUT", params, "COVID", cb);
  },
  deleteDocumentValue(id, cb) {
    return this.baseApi(`/admin/docvalues/${id}`, "DELETE", {}, "COVID", cb);
  },
  nftGeneralInfo(cb) {
    return this.baseApi_v2(
      "/nft/v1/admin/generalInfo",
      "GET",
      {},
      "NFT",
      {},
      cb
    );
  },
  nftAdminWallet(cb) {
    return this.baseApi_v2("/nft/v1/admin/wallet", "GET", {}, "NFT", {}, cb);
  },
  getFreeNFTs(numTokens, skip, cb) {
    return this.baseApi_v2(
      `/nft/v1/admin/freeNFTs?numTokens=${numTokens}&skip=${skip}`,
      "GET",
      {},
      "NFT",
      {},
      cb
    );
  },
  getAlertSetting(cb) {
    return this.baseApi_v2(
      "/nft/v1/admin/alertSetting",
      "GET",
      {},
      "NFT",
      {},
      cb
    );
  },
  editAlertSetting(params, cb) {
    return this.backendApi(`/nft/v1/admin/alertSetting`, "PUT", params, cb);
  },
  getAllSwappingTransactions(
    page,
    size,
    address,
    txHash,
    srcNetwork,
    destNetwork,
    status = "all",
    startDate,
    endDate,
    cb
  ) {
    let params = {
      page,
      size,
      status,
      address,
      transactionHash: txHash,
      srcNetwork,
      destNetwork,
      fromDate: startDate,
      toDate: endDate,
    };

    return this.baseApi_v2(`/admin/requests`, "GET", {}, "SWAP", params, cb);
  },
  approveTransaction(params, cb) {
    return this.baseApi("/admin/batches/approve", "POST", params, "SWAP", cb);
  },
  rejectTransaction(params, cb) {
    return this.baseApi("/admin/batches/reject", "POST", params, "SWAP", cb);
  },
  checkStatusTransaction(digest, cb) {
    return this.baseApi(
      `/admin/batches/${digest}/status`,
      "GET",
      {},
      "SWAP",
      cb
    );
  },
  cancelTransaction(digest, cb) {
    return this.baseApi(
      `/admin/batches/${digest}/cancel`,
      "POST",
      {},
      "SWAP",
      cb
    );
  },
  getAllSettings(cb) {
    return this.baseApi("/admin/settings", "GET", {}, "SWAP", cb);
  },
  updateSettings(params, cb) {
    return this.baseApi("/admin/settings", "POST", params, "SWAP", cb);
  },
  updateSwapFee(params, cb) {
    return this.baseApi("/admin/fees/update", "POST", params, "SWAP", cb);
  },
  checkStatusSwapFee(digest, cb) {
    return this.baseApi(
      `/admin/fees/update/${digest}/status`,
      "GET",
      {},
      "SWAP",
      cb
    );
  },
  cancelSwapFee(digest, cb) {
    return this.baseApi(
      `/admin/fees/update/${digest}/cancel`,
      "POST",
      {},
      "SWAP",
      cb
    );
  },
  getSwappingSummary(cb) {
    return this.baseApi("/admin/stats", "GET", {}, "SWAP", cb);
  },
  getDetailTransaction(id, cb) {
    return this.baseApi(`/admin/requests/${id}`, "GET", {}, "SWAP", cb);
  },
  exportTransactions(
    srcNetwork,
    destNetwork,
    startDate,
    endDate,
    address,
    txHash,
    cb
  ) {
    let params = {
      srcNetwork,
      destNetwork,
      from: startDate,
      to: endDate,
      address,
      transactionHash: txHash,
    };

    return this.baseApi_Swap_ExportCSV(
      `/admin/requests/export`,
      "GET",
      {},
      "SWAP",
      params,
      cb
    );
  },
  checkExceedingBalances(cb) {
    return this.baseApi(
      `/admin/requests/exceeding-balances`,
      "GET",
      {},
      "SWAP",
      cb
    );
  },
  getAllApprovers(page, size, cb) {
    return this.baseApi_v2(
      `/admin/approvers?page=${page}&size=${size}`,
      "GET",
      {},
      "SWAP",
      {},
      cb
    );
  },
  registerApprovers(cb) {
    return this.baseApi_v2(`/admin/approvers`, "POST", {}, "SWAP", {}, cb);
  },
  getUsersList(params, cb) {
    let newParams = {
      ...params,
      s: params.s === "" ? null : params.s,
    };
    return this.baseApi_v2("/admin/users", "GET", {}, "AUTH", newParams, cb);
  },
  inviteUser(data, cb) {
    return this.baseApi_v2(
      "/admin/users/invite-user",
      "POST",
      data,
      "AUTH",
      {},
      cb
    );
  },
  reInviteUser(data, cb) {
    return this.baseApi_v2(
      "/admin/users/reinvite-user",
      "POST",
      data,
      "AUTH",
      {},
      cb
    );
  },
  updateUser(id, firstName, lastName, adminRoleId, status, cb) {
    const data = { firstName, lastName, adminRoleId, status };
    return this.baseApi_v2(`/admin/users/${id}`, "PUT", data, "AUTH", {}, cb);
  },
  deleteUser(id, cb) {
    return this.baseApi_v2(`/admin/users/${id}`, "DELETE", {}, "AUTH", {}, cb);
  },
  getRolesList(params, cb) {
    let newParams = {
      sort: "-createdAt",
      s: params === "" ? null : params,
    };
    return this.baseApi_v2("/admin/roles", "GET", {}, "AUTH", newParams, cb);
  },
  createRole(roleName, displayName, modules, cb) {
    const data = {
      roleName,
      displayName,
      modules,
    };
    return this.baseApi_v2("/admin/roles", "POST", data, "AUTH", {}, cb);
  },
  updateRole(id, roleName, displayName, modules, cb) {
    const data = {
      roleName,
      displayName,
      modules,
    };
    return this.baseApi_v2(`/admin/roles/${id}`, "PUT", data, "AUTH", {}, cb);
  },
  deleteRole(id, cb) {
    return this.baseApi_v2(`/admin/roles/${id}`, "DELETE", {}, "AUTH", {}, cb);
  },
  getModules(cb) {
    return this.baseApi_v2("/admin/modules", "GET", {}, "AUTH", {}, cb);
  },
  getDetailUser(id, cb) {
    return this.baseApi_v2(`/admin/users/${id}`, "GET", {}, "AUTH", {}, cb);
  },
  getAuditLogs(params, cb) {
    let newParams = {
      ...params,
      s: params.s === "" ? null : params.s,
      sort: "-createdAt",
    };
    return this.baseApi_v2(
      "/admin/audit-logs",
      "GET",
      {},
      "AUTH",
      newParams,
      cb
    );
  },
  getNotificationLogs(params, cb) {
    let newParams = {
      ...params,
      s: params.s === "" ? null : params.s,
    };
    return this.baseApi_v2(
      "/admin/notification/logs",
      "GET",
      {},
      "AUTH",
      newParams,
      cb
    );
  },
  pushNotification(
    title,
    content,
    addresses,
    notificationType,
    appBundles,
    openScreenType,
    cb
  ) {
    return this.baseApi_v2(
      "/admin/notification/push",
      "POST",
      {
        title,
        content,
        addresses,
        notificationType,
        appBundles,
        openScreenType,
      },
      "AUTH",
      {},
      cb
    );
  },
  getUserOption(params, cb) {
    const newParams = {
      s: params.s === "" ? null : params.s,
    };
    return this.baseApi_v2(
      "/admin/notification/shr-user/search",
      "GET",
      {},
      "AUTH",
      newParams,
      cb
    );
  },
  exportCsvSmartEngine(params, cb) {
    return this.baseApi_Swap_ExportCSV(
      "/logs/export",
      "GET",
      {},
      "",
      params,
      cb
    );
  },
  getDistributionRules(treasuryWalletId, cb) {
    return this.baseApi_v2(
      "/distribution-rules",
      "GET",
      {},
      "RECON",
      { treasuryWalletId, getWallet: true },
      cb
    );
  },
  addDistributionRule(jsonData, cb) {
    return this.baseApi_v2(
      "/distribution-rules",
      "POST",
      jsonData,
      "RECON",
      {},
      cb
    );
  },
  deleteDistributionRule(id, cb) {
    return this.baseApi_v2(
      `/distribution-rules/${id}`,
      "DELETE",
      {},
      "RECON",
      {},
      cb
    );
  },
  getWallets(params, cb) {
    return this.baseApi_v2("/wallets", "GET", {}, "RECON", params, cb);
  },
  addWallet(jsonData, cb) {
    return this.baseApi_v2("/wallets", "POST", jsonData, "RECON", {}, cb);
  },
  deleteWallet(id, cb) {
    return this.baseApi_v2(`/wallets/${id}`, "DELETE", {}, "RECON", {}, cb);
  },
  getPublicKey(cb) {
    return this.baseApi_v2("/security/public-key", "GET", {}, "RECON", {}, cb);
  },
  getHaltRules(walletId, cb) {
    return this.baseApi_v2("/halt-rules", "GET", {}, "RECON", { walletId }, cb);
  },
  addHaltRule(jsonData, cb) {
    return this.baseApi_v2("/halt-rules", "POST", jsonData, "RECON", {}, cb);
  },
  changeHaltRule(id, jsonData, cb) {
    return this.baseApi_v2(
      `/halt-rules/${id}`,
      "PUT",
      jsonData,
      "RECON",
      {},
      cb
    );
  },
  getAllTransactions(params, cb) {
    return this.baseApi_v2(
      "/transactions?getWallet=true",
      "GET",
      {},
      "RECON",
      params,
      cb
    );
  },
  getNotificationEmails(walletId, cb) {
    const params = {
      walletId,
    };

    return this.baseApi_v2("/notifications", "GET", {}, "RECON", params, cb);
  },
  addNotificationEmails(walletId, emails, cb) {
    const jsonData = {
      walletId,
      emails,
    };
    return this.baseApi_v2(
      "/notifications/wallet",
      "POST",
      jsonData,
      "RECON",
      {},
      cb
    );
  },
  async getUsersLog(filter) {
    const options = {
      method: "GET",
      params: filter,
      url: "/admin/shr-users/logs",
    };

    try {
      const response = await axiosInstance("AUTH").request(options);
      return response?.data?.data || null;
    } catch (error) {
      throw error;
    }
  },
  getDVCTList(params, cb) {
    return this.baseApi_v2(
      "/dvct/admin/revoked-list",
      "GET",
      {},
      "IDENTITY",
      params,
      cb
    );
  },
  revokeDVCT(id, cb) {
    return this.baseApi_v2(
      "/dvct/admin/revoke",
      "POST",
      {
        tokenID: id,
      },
      "IDENTITY",
      {},
      cb
    );
  },
  getDiscordQueries(params, cb) {
    return this.baseApi_v2(
      `/queries?page=${params.page}&pageSize=${params.pageSize}`,
      "GET",
      {},
      "DISCORD",
      {},
      cb
    );
  },
  getDiscordQrRequests(params, cb) {
    return this.baseApi_v2(
      `/qrrequests?page=${params.page}&pageSize=${params.pageSize}`,
      "GET",
      {},
      "DISCORD",
      {},
      cb
    );
  },
  getQueryOwners(params, cb) {
    return this.baseApi_v2(
      "/admin/v1/query-owners",
      "GET",
      {},
      "VQL",
      params,
      cb
    );
  },
  createQueryOwner(jsonData, cb) {
    return this.baseApi_v2(
      "/admin/v1/query-owners",
      "POST",
      jsonData,
      "VQL",
      {},
      cb
    );
  },
  updateQueryOwner(id, jsonData, cb) {
    return this.baseApi_v2(
      `/admin/v1/query-owners/${id}`,
      "PUT",
      jsonData,
      "VQL",
      {},
      cb
    );
  },
  deleteQueryOwner(id, cb) {
    return this.baseApi_v2(
      `/admin/v1/query-owners/${id}`,
      "DELETE",
      {},
      "VQL",
      {},
      cb
    );
  },
  getCompanyNames(params, cb) {
    return this.baseApi_v2(
      "admin/v1/vql-clients",
      "GET",
      {},
      "VQL",
      params,
      cb
    );
  },
  getQueryOwnersForDownload(params, cb) {
    return this.baseApi_v2(
      "/admin/v1/sharering-wallets",
      "GET",
      {},
      "VQL",
      params,
      cb
    );
  },
  downloadQueryReport(jsonData, cb) {
    return this.baseApi_v2(
      "/admin/v1/query-report-requests",
      "POST",
      jsonData,
      "VQL",
      {},
      cb
    );
  },
  pollingDownloadQueryReport(id, cb) {
    return this.baseApi_v2(
      `/admin/v1/query-report-requests/${id}`,
      "GET",
      {},
      "VQL",
      {},
      cb
    );
  },
  startToDownloadQueryReport(id, cb) {
    return this.baseApi_downloadVQL(
      `/admin/v1/query-report-requests/${id}/download`,
      "GET",
      {},
      "VQL",
      {},
      cb
    );
  },
  getClients(params, cb) {
    return this.baseApi_v2(
      "admin/v1/vql-clients",
      "GET",
      {},
      "VQL",
      params,
      cb
    );
  },
  createClient(params, cb) {
    return this.baseApi_v2(
      "admin/v1/vql-clients",
      "POST",
      params,
      "VQL",
      {},
      cb
    );
  },
  checkExistedSubdomain(params, cb) {
    return this.baseApi_v2(
      "/v1/vql-clients/check-existed-subdomain",
      "POST",
      params,
      "VQL",
      {},
      cb
    );
  },
  checkExistedCompanyName(params, cb) {
    return this.baseApi_v2(
      "/v1/vql-clients/check-existed-company-name",
      "POST",
      params,
      "VQL",
      {},
      cb
    );
  },
  updateClient(params, cb) {
    const { id, ...jsonData } = params;
    return this.baseApi_v2(
      `/v1/vql-clients/${id}`,
      "PUT",
      jsonData,
      "VQL",
      {},
      cb
    );
  },
  getClientDetails(id, cb) {
    return this.baseApi_v2(`/v1/vql-clients/${id}`, "GET", {}, "VQL", {}, cb);
  },
  getVqlRoles(params, cb) {
    return this.baseApi_v2("/v1/vql-roles", "GET", {}, "VQL", params, cb);
  },
  getVqlUsers(params, cb) {
    return this.baseApi_v2(
      `/v1/vql-clients/${params.clientId}/vql-users`,
      "GET",
      {},
      "VQL",
      params,
      cb
    );
  },
  getStatusInformations(clientId, cb) {
    return this.baseApi_v2(
      `/v1/vql-clients/${clientId}/vql-users/stats`,
      "GET",
      {},
      "VQL",
      {},
      cb
    );
  },
  updateVqlUser(params, cb) {
    const { clientId, id, roleName } = params;
    return this.baseApi_v2(
      `/v1/vql-clients/${clientId}/vql-users/${id}`,
      "PUT",
      { roleName },
      "VQL",
      {},
      cb
    );
  },
  checkLastAdmin(clientId, cb) {
    return this.baseApi_v2(
      `/v1/vql-clients/${clientId}/vql-users/check-last-admin`,
      "GET",
      {},
      "VQL",
      {},
      cb
    );
  },
  revokeVqlUser(params, cb) {
    const { clientId, id } = params;
    return this.baseApi_v2(
      `/v1/vql-clients/${clientId}/vql-users/${id}/revoke`,
      "POST",
      {},
      "VQL",
      {},
      cb
    );
  },
  generateInvitation(params, cb) {
    return this.baseApi_v2(
      `/v1/vql-clients/${params?.clientId}/vql-users/generate-invitation`,
      "POST",
      params,
      "VQL",
      {},
      cb
    );
  },
  sentVqlEmailInvitation(params, cb) {
    return this.baseApi_v2(
      `/v1/vql-clients/${params?.clientId}/vql-users/send-mail-invitation`,
      "POST",
      params,
      "VQL",
      {},
      cb
    );
  },
};
