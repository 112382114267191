const data = [
  {
    id: "dashboard",
    icon: "iconsminds-dashboard",
    label: "Dashboard",
    to: "/app/dashboard",
  },
  {
    id: "accommodation",
    icon: "iconsminds-hotel",
    label: "Accommodation",
    to: "/app/accommodation",
  },
  {
    id: "test_center",
    icon: "iconsminds-heart",
    label: "Test Center",
    to: "/app/testCenters",
  },
  {
    id: "kyc_issuer",
    icon: "iconsminds-business-man",
    label: "Kyc Issuers",
    to: "/app/kycIssuers",
  },
  {
    id: "enroll_log",
    icon: "iconsminds-file",
    label: "Enroll Logs",
    to: "/app/userHistories",
  },
  {
    id: "prefund",
    icon: "iconsminds-file",
    label: "Prefunds",
    to: "/app/prefunds",
  },
  {
    id: "access",
    icon: "iconsminds-cricket",
    label: "Access",
    to: "/app/access",
  },
  {
    id: "promotion",
    icon: "iconsminds-bucket",
    label: "Promotions",
    to: "/app/promotions",
    subs: [
      {
        icon: "iconsminds-bucket",
        label: "Homescreen Banner",
        to: "/app/promotions/homescreen-promotion",
      },
      {
        icon: "iconsminds-bucket",
        label: "Other Promotion",
        to: "/app/promotions/other-promotion",
      },
    ],
  },
  {
    id: "operator",
    icon: "iconsminds-management",
    label: "Operators",
    to: "/app/operators",
  },
  {
    id: "hub",
    icon: "iconsminds-hub",
    label: "Hubs",
    to: "/app/hubs",
  },
  {
    id: "shop",
    icon: "iconsminds-shop-4",
    label: "Shops",
    to: "/app/shops",
    subs: [
      {
        icon: "simple-icon-briefcase",
        label: "Shops",
        to: "/app/shops/shop-list",
      },
      {
        icon: "simple-icon-pie-chart",
        label: "Add Shop",
        to: "/app/shops/shop-detail/0",
      },
    ],
  },
  {
    id: "region",
    icon: "iconsminds-post-office",
    label: "Regions",
    to: "/app/regions",
    subs: [
      {
        icon: "iconsminds-berlin-tower",
        label: "Countries",
        to: "/app/regions/countries",
      },
      {
        icon: "iconsminds-taj-mahal",
        label: "States",
        to: "/app/regions/states",
      },
      {
        icon: "iconsminds-city-hall",
        label: "City",
        to: "/app/regions/cities",
      },
      {
        id: "languages",
        icon: "iconsminds-communication-tower-2",
        label: "menu.languages",
        to: "/app/regions/languages",
      },
    ],
  },
  {
    id: "user",
    icon: "simple-icon-user",
    label: "Users",
    to: "/app/users",
  },
  {
    id: "usermanagement",
    icon: "simple-icon-user",
    label: "User Management",
    to: "/app/user-management",
  },
  {
    id: "vql",
    icon: "iconsminds-books",
    label: "VQL Management",
    to: "/app/vql-management",
    subs: [
      {
        id: "queryOwner",
        icon: "simple-icon-user",
        label: "Query Owner",
        to: "/app/vql-management/query-owner",
      },
      {
        id: "queryReport",
        icon: "simple-icon-note",
        label: "Query Report",
        to: "/app/vql-management/query-report",
      },
      {
        id: "clientProfile",
        icon: "simple-icon-people",
        label: "Client Profile",
        to: "/app/vql-management/client-profile",
      },
    ],
  },
  {
    id: "category",
    icon: "iconsminds-el-castillo",
    label: "Categories",
    to: "/app/categories",
  },
  {
    id: "product",
    icon: "iconsminds-mine",
    label: "Products",
    to: "/app/products",
    subs: [
      {
        icon: "simple-icon-briefcase",
        label: "Products",
        to: "/app/products/products",
      },
      {
        icon: "simple-icon-pie-chart",
        label: "Add Product",
        to: "/app/products/product-detail/0",
      },
      {
        icon: "simple-icon-notebook",
        label: "Product Option",
        to: "/app/products/productoptions",
      },
    ],
  },
  {
    id: "seller_product",
    icon: "iconsminds-mine",
    label: "Seller Products",
    to: "/app/sellerproducts",
    subs: [
      {
        icon: "simple-icon-briefcase",
        label: "Seller Products",
        to: "/app/sellerproducts/list",
      },
      {
        icon: "simple-icon-pie-chart",
        label: "Add Seller Product",
        to: "/app/sellerproducts/product-detail/0",
      },
    ],
  },
  {
    id: "brand",
    icon: "iconsminds-radioactive",
    label: "Brands",
    to: "/app/brands",
  },
  {
    id: "log",
    icon: "iconsminds-books",
    label: "Application Logs",
    to: "/app/loglist",
    subs: [
      {
        icon: "simple-icon-camera",
        label: "SmartEngine Logs",
        to: "/app/loglist/smartEngineLogs",
      },
      {
        icon: "iconsminds-books",
        label: "FaceMatch Logs",
        to: "/app/loglist/faceMatchLogs",
      },
      {
        icon: "iconsminds-books",
        label: "DetectFace Logs",
        to: "/app/loglist/detectFaceLogs",
      },
      {
        icon: "simple-icon-globe",
        label: "Tecent API Regions",
        to: "/app/loglist/tecentApiRegions",
      },
    ],
  },
  {
    id: "doc_field",
    icon: "iconsminds-data-center",
    label: "Document Fields",
    to: "/app/documentFields",
    subs: [
      {
        icon: "iconsminds-snowflake-3",
        label: "Vaccine",
        to: "/app/documentFields/vaccine",
      },
      {
        icon: "iconsminds-empire-state-building",
        label: "Member State",
        to: "/app/documentFields/memberState",
      },
      {
        icon: "iconsminds-qr-code",
        label: "Medicinal Product",
        to: "/app/documentFields",
      },
      {
        id: "manufacturerCode",
        icon: "iconsminds-police",
        label: "Manufacturer",
        to: "/app/documentFields/manufacturerCode",
      },
    ],
  },
  {
    id: "swap",
    icon: "iconsminds-sync",
    label: "Swap",
    to: "/app/swap",
    subs: [
      {
        icon: "iconsminds-sync",
        label: "Swap",
        to: "/app/swap/swap",
      },
      {
        icon: "iconsminds-books",
        label: "History",
        to: "/app/swap/history",
      },
      {
        icon: "simple-icon-user",
        label: "Approvers",
        to: "/app/swap/approvers",
      },
    ],
  },
  {
    id: "nft",
    icon: "iconsminds-photo",
    label: "NFTs",
    to: "/app/nfts",
  },
  {
    id: "vct_revoke",
    icon: "iconsminds-repeat",
    label: "VCT Revoke",
    to: "/app/vct-revoke",
  },
  {
    id: "discord_bot",
    icon: "iconsminds-library",
    label: "Discord Bot",
    to: "/app/discordBot",
    subs: [
      {
        id: "queries",
        icon: "iconsminds-qr-code",
        label: "Query",
        to: "/app/discordBot/queries",
      },
      {
        id: "requests",
        icon: "iconsminds-save",
        label: "Request",
        to: "/app/discordBot/requests",
      },
    ],
  },
  {
    id: "setting",
    icon: "simple-icon-settings",
    label: "Setting",
    to: "/app/setting",
    subs: [
      {
        id: "swap",
        icon: "iconsminds-sync",
        label: "Swap",
        to: "/app/setting/swap",
      },
      {
        id: "NFTs",
        icon: "iconsminds-photo",
        label: "NFTs",
        to: "/app/setting/nfts",
      },
      {
        id: "notifcations",
        icon: "iconsminds-bell",
        label: "menu.notification",
        to: "/app/setting/notification",
      },
      {
        id: "auto_topup",
        icon: "iconsminds-light-bulb-2",
        label: "menu.auto-topup",
        to: "/app/setting/auto-topup",
      },
      {
        id: "auto_topup_history",
        icon: "iconsminds-books",
        label: "menu.auto-topup-history",
        to: "/app/setting/auto-topup-history",
      },
    ],
  },
];

export default data;
