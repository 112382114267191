import axios from "axios";
import { handleInvalidToken, redirectLogin } from "../../redux/auth/actions";

const URL = {
  AUTH: process.env.REACT_APP_AUTH_API_URL,
  SWAP: process.env.REACT_APP_SWAP_API_URL,
  COVID: process.env.REACT_APP_COVID_API_URL,
  LOGIN: process.env.REACT_APP_LOGIN_API_URL,
  RECON: process.env.REACT_APP_RECON_API_URL,
  NFT: process.env.REACT_APP_API_URL,
  IDENTITY: process.env.REACT_APP_IDENTITY_API_URL,
  VQL: process.env.REACT_APP_VQL_API_URL,
  DISCORD: process.env.REACT_APP_DISCORD_MS_URL
};

const getUrl = (type) => {
  return URL[type] ?? process.env.REACT_APP_API_URL;
};

const axiosInstancesObjects = {};

// avoid init axiosInstance many times if the same url
export const axiosInstance = (type) => {
  if (axiosInstancesObjects[type]) {
    return axiosInstancesObjects[type];
  }
  const instance = axios.create({
    baseURL: getUrl(type),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstancesObjects[type] = instance;
  return instance;
};

// init axiosInstance for 4 keys with 4 url
Object.keys(URL).forEach((k) => axiosInstance(k));

export const getAllAxiosInstances = () => {
  // return Object.keys(axiosInstancesObjects).map((k) => axiosInstancesObjects[k]);
  return Object.values(axiosInstancesObjects);
};

export const interceptor = (store) => {
  Object.keys(URL).forEach((k) => {
    const axiosIns = axiosInstancesObjects[k];
    axiosIns.interceptors.request.use(
      (conf) => {
        // you can add some information before send it.
        // conf.headers['Auth'] = 'some token'
        if (conf.url.indexOf("/token") !== -1) {
          return conf;
        }

        const token = store.getState().authUser.token;
        if (!token) {
          return conf;
        }
        conf.headers["Authorization"] = `Bearer ${token}`;
        return conf;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axiosIns.interceptors.response.use(
      (next) => {
        return Promise.resolve(next);
      },
      (error) => {
        if (error.response.status === 401) {
          if (error.response.data.name === "UNAUTHORIZED") {
            // if (!store.getState().authUser.isRefreshingToken) {
            //   store.dispatch(refreshToken());
            // }
            store.dispatch(redirectLogin());
            return;
            // return new Promise((res) => {
            //   setTimeout(() => {
            //     res(axiosIns(error.config));
            //   }, 1000);
            // });
          }
        }

        if (error.config.url.indexOf("/token") !== -1) {
          store.dispatch(handleInvalidToken());
        }

        return Promise.reject(error);
      }
    );
  });
};
