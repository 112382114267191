import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  REDIRECT_LOGIN,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_MENU_LIST,
  GET_MENU_LIST_SUCCESS,
  SET_USER_TOKEN,
  REFRESH_TOKEN,
  SET_REFRESHING_TOKEN,
  GET_USER_INFO_ERROR,
  HANDLE_INVALID_TOKEN,
  CHECK_AUTHORIZATION,
  SET_USER_SCOPE,
} from "../actions";

export const loginUser = (code) => ({
  type: LOGIN_USER,
  payload: { code },
});

export const loginUserSuccess = (user, token, refreshToken) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { user, token, refreshToken },
});

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const setUserToken = (accessToken, refreshToken) => ({
  type: SET_USER_TOKEN,
  payload: { token: accessToken, refreshToken },
});

export const refreshToken = () => ({
  type: REFRESH_TOKEN,
});

export const setRefreshingToken = () => ({
  type: SET_REFRESHING_TOKEN,
});

export const handleInvalidToken = () => ({
  type: HANDLE_INVALID_TOKEN,
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
});

export const redirectLogin = () => ({
  type: REDIRECT_LOGIN,
});

export const getUserInfo = (userId) => ({
  type: GET_USER_INFO,
  payload: { userId },
});

export const getUserInfoSuccess = (userInfo) => ({
  type: GET_USER_INFO_SUCCESS,
  payload: userInfo,
});

export const getMenuList = (userId) => ({
  type: GET_MENU_LIST,
  payload: { userId },
});

export const getMenuListSuccess = (menuList) => ({
  type: GET_MENU_LIST_SUCCESS,
  payload: menuList,
});

export const getUserInfoError = (message) => ({
  type: GET_USER_INFO_ERROR,
  payload: { message },
});

export const checkAuthorization = () => ({
  type: CHECK_AUTHORIZATION,
});

export const setUserScope = (scope) => ({
  type: SET_USER_SCOPE,
  payload: { scope },
});
