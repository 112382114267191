import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { interceptor } from "../service/api/axiosInstance";
import reducers from "./reducers";
import rootSaga from "./rootSaga";

const configureStore = (initialState) => {
  const sagaMiddleware = createSagaMiddleware();

  return {
    ...createStore(reducers, initialState, applyMiddleware(sagaMiddleware)),
    runSaga: sagaMiddleware.run,
  };
};

export const store = configureStore();
interceptor(store);
store.runSaga(rootSaga);
